import React, { useEffect, useState } from 'react'

import { NotFound } from '../components/NotFound'

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false)
  useEffect(() => {
    setMount(true)
  }, [])
  return isMount && <NotFound />
}

export default NotFoundPage
export const Head = () => <title>Datacapt</title>
